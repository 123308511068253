<template>
  <div class="aftersales main">
    <div class="merch-card scorr-roll">
      <div v-if="ofPageData">
        <!-- 顶部输入框 -->
        <div class="Search">
          <!-- 售后订单 -->
          <div class="search-box">
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title">售后订单号:</div> -->
              <el-input
                clearable
                placeholder="请输入售后订单号"
                v-model="tableData.afterSalesOrderNo"
              ></el-input>
            </div>
            <!-- 订单编号 -->
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title exceed">订单编号:</div> -->
              <el-input
                clearable
                placeholder="请输入订单编号"
                v-model="tableData.orderNumber"
              >
              </el-input>
            </div>

            <!-- 售后订单类型 -->
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title exceed">售后订单类型:</div> -->
              <el-select
                v-model="tableData.salesTypeMoul"
                clearable
                placeholder="请选择售后订单类型"
              >
                <el-option
                  v-for="item in this.statusValue.type"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <!-- 货物状态 -->
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title exceed">货物状态:</div> -->
              <el-select
                clearable
                v-model="tableData.cargoStatusMoul"
                placeholder="请选择货物状态"
              >
                <el-option
                  v-for="item in this.statusValue.cargo"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <!-- 退款状态 -->
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title exceed">退款状态:</div> -->
              <el-select
                clearable
                v-model="tableData.refundStatusMoul"
                placeholder="请选择退款状态"
              >
                <el-option
                  v-for="item in this.statusValue.refund_status"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <!-- 申请单位 -->
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title exceed">申请单位:</div> -->
              <el-input
                clearable
                placeholder="请输入申请单位"
                v-model="tableData.applicant"
              >
              </el-input>
            </div>

            <!-- 供应供应商 -->
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title exceed">供应供应商:</div> -->
              <el-input
                clearable
                placeholder="请输入共享供应商"
                v-model="tableData.supplier"
              >
              </el-input>
            </div>

            <!-- 销售供应商 -->
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title exceed">销售供应商:</div> -->
              <el-input
                clearable
                placeholder="请输入销售供应商"
                v-model="tableData.salesMerchant"
              ></el-input>
            </div>

            <!-- 支付方式 -->
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title exceed">支付方式:</div> -->
              <el-select
                clearable
                v-model="tableData.paymentMethodMoul"
                placeholder="请选择支付方式"
              >
                <el-option
                  v-for="item in payOption"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </div>

            <!-- 平台介入状态 -->
            <div class="sch-1 sch-2">
              <!-- <div class="sch-title exceed">平台介入状态:</div> -->
              <el-select
                clearable
                v-model="tableData.interventionStatusIpt"
                placeholder="请选择平台介入状态"
              >
                <el-option
                  v-for="item in this.statusValue.intervention"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <!-- 日期查询 -->
        <div class="dateQuery">
          <div class="queryTitle">订单创建时间</div>
          <el-date-picker
            v-model="tableData.orderCreationTime"
            type="datetimerange"
            start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间"
            :default-time="['00:00:00', '23:59:59']"
            align="left"
            @change="handleChooseCreate"
          />
          <div class="fterText">售后订单创建时间</div>
          <el-date-picker
            clearable
            v-model="tableData.salesOrderTime"
            type="datetimerange"
            start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间"
            :default-time="['00:00:00', '23:59:59']"
            align="left"
            @change="handleChooseSales"
          />
          <el-button
            type="primary"
            class="searchBtn"
            @click="getAfterSalesSearch()"
            >查 询</el-button
          >
          <el-button @click="reset()">重置</el-button>
          <!-- 只看平台介入单 -->
          <div class="justLook">
            <el-checkbox v-model="checked" @change="justLookM"
              >只看平台介入单</el-checkbox
            >
          </div>
        </div>
        <!--筛选列表-->
        <div class="sign">
          <!-- 左边 -->
          <div class="rightBtn">
            <div style="display: flex">
              <div
                v-for="(item, index) in linst"
                :key="index"
                class="defaultStyle"
                :class="codeStyle === index ? 'opp' : ''"
                @click="add(item, index)"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <!-- {{afterSaleList}}? -->
        <!-- 表格数据 -->
        <div class="tableData">
          <el-table
            v-loading="loading"
            :data="afterSaleList.data"
            border
            style="width: 100%"
            :row-style="{ height: '65px' }"
            :cell-style="{ padding: '0' }"
          >
            <!-- 序号 -->
            <el-table-column fixed label="序号" width="50" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.index }}</p>
              </template>
            </el-table-column>
            <!-- 售后单号/申请时间 -->
            <el-table-column
              prop="after_sales_id,create_time"
              label="售后单号/申请时间"
              width="200"
              class="pPing"
              align="center"
            >
              <template slot-scope="scope">
                <p>{{ scope.row.after_sales_id }}</p>
                <p>{{ scope.row.create_time }}</p>
              </template>
            </el-table-column>
            <!-- 订单编号/订单创建时间 -->
            <el-table-column
              prop="orderNumber,order_create_time"
              label="订单编号/创建时间"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <p>{{ scope.row.order_id }}</p>
                <p>{{ scope.row.order_create_time }}</p>
              </template>
            </el-table-column>
            <!-- 申请单位 -->
            <el-table-column
              prop="user_name"
              label="申请单位"
              width="200"
              align="center"
            />
            <!-- 共享供应商 -->
            <el-table-column
              prop="share_business_name"
              label="共享供应商"
              width="200"
              align="center"
            />
            <!-- 销售供应商 -->
            <el-table-column
              prop="business_name"
              label="销售供应商"
              width="200"
              align="center"
            />
            <!-- 售后类型 -->
            <el-table-column
              prop="type"
              label="售后类型"
              width="200"
              align="center"
            />
            <!-- 收货人/联系电话 -->
            <el-table-column
              prop="consignee_name,consignee_mobile"
              label="收货人/联系电话"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <p>{{ scope.row.consignee_name }}</p>
                <p>{{ scope.row.consignee_mobile }}</p>
              </template>
            </el-table-column>
            <!-- 退货条目数 -->
            <el-table-column
              prop="goods_item_num"
              label="退货条目数"
              width="200"
              align="center"
            />
            <!-- 退款金额 -->
            <el-table-column
              prop="refund_amount"
              label="退款金额(元)"
              width="200"
              align="center"
            />
            <!-- 支付方式 -->
            <el-table-column
              prop="payTypeString"
              label="支付方式"
              width="200"
              align="center"
            />
            <!-- 售后状态 -->
            <el-table-column
              prop="state"
              label="售后状态"
              width="200"
              align="center"
            />
            <!-- 退款状态 -->
            <el-table-column
              prop="refund_status"
              label="退款状态"
              width="200"
              align="center"
            />
            <!-- 介入进度 -->
            <el-table-column
              prop="platform_state.status_"
              label="介入进度"
              width="200"
              align="center"
            />
            <!-- 退货物流公司 物流单号-  -->
            <el-table-column
              prop="logisticsCompany,waybillNumber"
              label="退货物流信息"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <p>{{ scope.row.logisticsCompany }}</p>
                <p>{{ scope.row.waybillNumber }}</p>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="handleClick(scope.row)"
                  v-if="allJurisdiction.aftersales130"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="panze(scope.row)"
                  v-if="
                    (scope.row.platform_state.status_ == '举证中' ||
                    scope.row.platform_state.status_ == '平台判责中'
                      ? true
                      : false) && allJurisdiction.aftersales131
                  "
                  >判责</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="jieru(scope.row)"
                  v-if="
                    (scope.row.platform_state.status_ == '等待平台介入' ||
                    scope.row.platform_state.status_ == 0
                      ? true
                      : false) && allJurisdiction.aftersales132
                  "
                  >确认介入</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="paging">
            <!-- sizes,  -->
            <el-pagination
              background
              layout="total, prev, pager, next, jumper"
              :page-size="afterSaleList.per_page"
              :total="afterSaleList.total"
              :current-page.sync="currentPage1"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>

        <!-- 平台介入确认提醒 -->
        <el-dialog
          title="平台介入确认提醒"
          :visible.sync="approved"
          width="30%"
        >
          <span>
            用户 {{ approvedMess.user_name }} 对售后订单
            {{ approvedMess.after_sales_id }}
            申请平台介入，点击确定按钮将进入处理中环节。
          </span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="approved = false">取 消</el-button>
            <el-button type="primary" @click="approvedTo()">确定</el-button>
          </span>
        </el-dialog>
      </div>
      <!-- if隐藏显示订单审核 -->
      <div v-else class="OrderDetails">
        <orderDetails />
      </div>
    </div>
  </div>
</template>

<script>
import orderDetails from "../order/orderDetails.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("afterSalesOrder"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const getOrder = createNamespacedHelpers("Order"); //vuex公共库
export default {
  name: "Aftersales",
  components: {
    orderDetails,
  },
  data() {
    return {
      payOption:[],
      tableData: {
        afterSalesOrderNo: "", //售后订单号
        orderNumber: "", //订单编号
        salesTypeMoul: "", //售后订单类型
        cargoStatusMoul: "", //货物状态
        refundStatusMoul: "", //退款状态
        applicant: "", //申请单位
        supplier: "", //供应供应商
        salesMerchant: "", //销售供应商
        paymentMethodMoul: "", //支付方式
        interventionStatusIpt: "", //平台介入状态
        NewOrderCreationTime: [], //订单创建时间
        NewsalesOrderTime: [], //售后订单创建时间
        orderCreationTime: [], //已处理订单创建时间
        salesOrderTime: [], //已处理售后订单创建时间
        business_state: "", //售后订单状态
        intervention: "", //是否平台介入订单
      },
      approvedMess: {},
      approved: false, //平台介入弹框
      loading: false, //数据加载动画

      // 分页
      currentPage1: 1, //当前选中的页码

      // 点击标记
      linst: [
        "全部",
        "待审核",
        "待发货",
        "待收货",
        "已完成",
        "已驳回",
        "已取消",
      ],
      codeStyle: 0, // 默认选项
      itemdata: "全部", // 什么分类

      operationId: "", // 操作数据id
      checked: false, //只看平台介入框是否勾选
    };
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.tableData = this.detailsSeach[router];
      this.currentPage1 = this.detailsSeach[router].currentPage1;
      if (this.detailsSeach[router].intervention == 1) {
        this.checked = true;
      }
    }
    this.searchApi();
    this.searchCriteriaData(); //搜索条件状态
    //前端获取支付方式列表
    this.getPaymentTypeList().then((res) => {
      if (res.code == 200) {
        this.payOption = res.data;
      }
    });
  },
  computed: {
    ...mapState([
      "statusValue",
      "afterSaleList",
      "ofPageData",
      "interventionStatus",
      "count",
    ]),
    ...commonIndex.mapState(["allJurisdiction", "detailsSeach"]),
  },
  destroyed() {
    this.ofPage(true);
  },
  methods: {
    ...mapActions([
      "search",
      "searchCriteriaData",
      "setPlatformintervention",
      "afterSaleId",
      "ofPage",
      "afterSalesOrderListState",
      "changeCount",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    ...getOrder.mapActions(["getPaymentTypeList"]),
    handleChooseCreate(){
      if(this.tableData.orderCreationTime){
        this.tableData.NewOrderCreationTime = [this.timestampToTime(this.tableData.orderCreationTime[0]),this.timestampToTime(this.tableData.orderCreationTime[1])] 
      }else{
        this.tableData.NewOrderCreationTime = []
      }
    },
    handleChooseSales(){
      if(this.tableData.salesOrderTime){
        this.tableData.NewsalesOrderTime = [this.timestampToTime(this.tableData.salesOrderTime[0]),this.timestampToTime(this.tableData.salesOrderTime[1])] 
      }else{
        this.tableData.NewsalesOrderTime = []
      }
    },
    async searchApi() {
      if (this.checked) {
        this.tableData.intervention = 1;
      } else {
        this.tableData.intervention = "";
      }
      // 处理时间
      let params = {
        page: this.currentPage1,
        after_order_id: this.tableData.afterSalesOrderNo,
        order_id: this.tableData.orderNumber,
        type: this.tableData.salesTypeMoul,
        cargo: this.tableData.cargoStatusMoul,
        reason: this.tableData.refundStatusMoul,
        user_name: this.tableData.applicant,
        business_name: this.tableData.supplier,
        share_name: this.tableData.salesMerchant,
        pay_type: this.tableData.paymentMethodMoul,
        platform_status_: this.tableData.interventionStatusIpt,
        order_create_time: this.tableData.NewOrderCreationTime,
        after_create_time: this.tableData.NewsalesOrderTime,
        business_state: this.tableData.business_state,
        intervention: this.tableData.intervention,
      };
      //搜索请求
      this.search(params);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.tableData };
      save.currentPage1 = this.currentPage1;
      save.router = router;
      this.changeSeach(save);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //平台介入
    async approvedTo() {
      this.approved = false;
      let datai = await this.setPlatformintervention({ id: this.operationId });
      if (datai.code == 200) {
        this.searchApi();
      }
    },
    //只看平台介入单
    justLookM() {
      
      this.currentPage1 = 1;
      this.searchApi();
    },
    // 筛选判断
    judgeClick() {
      if (this.itemdata == "全部") {
        this.tableData.business_state = "";
      } else if (this.itemdata == "待审核") {
        this.tableData.business_state = 0;
      } else if (this.itemdata == "待发货") {
        this.tableData.business_state = 2;
      } else if (this.itemdata == "待收货") {
        this.tableData.business_state = 3;
      } else if (this.itemdata == "已完成") {
        this.tableData.business_state = 4;
      } else if (this.itemdata == "已驳回") {
        this.tableData.business_state = 1;
      } else if (this.itemdata == "已取消") {
        this.tableData.business_state = -1;
      }
      this.currentPage1 = 1;
      this.searchApi();
    },
    // 点击筛选
    add(item, index) {
      this.itemdata = item;
      this.checked = false;
      this.codeStyle = index;
      this.currentPage1 = 1;
      this.judgeClick();
    },
    // 翻页
    handleCurrentChange(val) {
      this.currentPage1 = val;
      this.searchApi();
      // if (this.checked == true) {
      //   this.justLookM();
      // } else {
      //   this.judgeClick();
      // }
      //console.log(`当前页: ${val}`);
    },
    // 重置
    reset() {
      this.tableData.afterSalesOrderNo = ""; //售后订单号
      this.tableData.orderNumber = ""; //订单编号
      this.tableData.salesTypeMoul = ""; //售后订单类型
      this.tableData.cargoStatusMoul = ""; //货物状态
      this.tableData.refundStatusMoul = ""; //退款状态
      this.tableData.applicant = ""; //申请单位
      this.tableData.supplier = ""; //供应供应商
      (this.tableData.salesMerchant = ""), //销售供应商
        (this.tableData.paymentMethodMoul = ""), //支付方式
        (this.tableData.interventionStatusIpt = ""), //平台介入状态
        (this.tableData.NewOrderCreationTime = []); //已处理订单创建时间
      this.tableData.NewsalesOrderTime = []; //已处理售后订单创建时间
      this.tableData.orderCreationTime = []; //订单创建时间
      this.tableData.salesOrderTime = []; //售后订单创建时间
      //console.log(this.statusValue);
      // //console.log(this.afterSaleList);
      this.getAfterSalesSearch();
    },
    // 搜索
    async getAfterSalesSearch() {
      this.currentPage1 = 1;
      this.searchApi();
      //console.log(this.tableData.salesTypeMoul);
    },
    // 处理时间格式函数
    timestampToTime(timestamp, data) {
      var chinaStandard = timestamp;
      var date = new Date(chinaStandard);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var second = date.getSeconds();
      second = second < 10 ? "0" + second : second;
      let timeStr = y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second
      return timeStr
    },
    // 详情
    handleClick(tab, event) {
      this.$router.push({
        path: "/orderDetails",
        query: { active: "审核", id: tab.after_sales_id },
      });
      this.afterSaleId(tab.after_sales_id);
    },
    // 判责
    panze(tab, event) {
      this.afterSaleId(tab.after_sales_id);
      this.ofPage(false);
    },
    // 介入
    jieru(tab, event) {
      this.operationId = tab.after_sales_id;
      this.approvedMess = tab;
      this.approved = true;
    },
  },
};
</script>
<style lang='scss' scoped>
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: black;
}
.el-checkbox__label {
  color: black;
}
.el-badge__content {
  border-radius: 0px;
  padding: 0 3px;
  color: black;
}
.el-input--small .el-input__inner {
  width: 145px;
}

.el-badge__content.is-fixed {
  right: 30px;
}
.el-input .el-input__inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    min-width: 1000px;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
  }
  .inputTop {
    margin-bottom: 10px;
  }
  .dateQuery {
    // width: 1220px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    .queryTitle,
    .fterText {
      font-size: 14px;
      margin-right: 10px;
    }
    .fterText {
      margin-left: 20px;
    }
    .searchBtn {
      margin-left: 20px;
    }
  }
  .sign {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .paging {
    margin-top: 10px;
    text-align: right;
  }
  .opp {
    color: #fff;
    border-radius: 5px;
    background-color: #06b7ae;
  }
  .defaultStyle {
    width: 100px;
    padding: 10px 20px;
    text-align: center;
    line-height: 100%;
    font-size: 14px;
    cursor: pointer;
  }
  .tail {
    display: flex;
    justify-content: flex-end;
  }
}

.Search {
  width: 100%;
  .search-box {
    display: flex;
    flex-wrap: wrap;
  }
  .sch-1 {
    margin-top: 10px;
    // width: 250px;
    min-width: 250px;
    display: flex;
    .sch-title {
      width: 40%;
      min-width: 80px;
      height: 100%;
      font-size: 13px;
      background-color: #f5f7fa;
      color: #909399;
      border: 1px solid #dcdfe6;
      border-right: none;
      border-radius: 4px 0px 0px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 0px 0px 5px !important;
    }
  }
  .sch-2 {
    margin-right: 2%;
  }
}

p {
  margin: 0;
  padding: 0;
}
.justLook {
  margin-left: 50px;
}
::v-deep .el-select {
  width: 100%;
}
</style>
